<template>
    <master-panel>
        <template v-slot:body>
            <loading-spinner v-if="loading" line height=""/>
            <v-row v-else>
                <v-col cols="12" class="title">
                    {{ currentMonth }}
                </v-col>
                <v-col cols="12" v-if="labelsEmpty.length > 0" class="text-center py-0">
                    <span v-for="label in labelsEmpty" :key="label" v-text="`${label}: 0`" class="labels mr-3"/>
                </v-col>
                <v-col cols="12" class="mb-3">
                    <pie-chart no-title :labels="labels" :datasets="datasets"/>
                </v-col>
                <v-col cols="12" v-for="(statistic,i) in statistics" :key="i" class="mb-1">
                    <statistics-count :statistic="statistic"/>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="secondary" class="normal-btn" small depressed @click="goBack">
                Volver
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import StatisticsCount from "@/components/lists/StatisticsCount";
import PieChart from "@/components/common/PieChart";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";

export default {
    name: "Statistics",
    components: {LoadingSpinner, PieChart, StatisticsCount, MasterPanel},
    data() {
        return {
            loading: false,
            statistics: [
                {name: 'Total cirugías anual', icon: '$knife', count: 0},
                {name: 'Total pacientes anual', icon: '$human', count: 0},
                {name: 'Total mujeres', icon: '$woman', count: 0},
                {name: 'Total hombres', icon: '$man', count: 0},
                {name: 'Edad promedio', icon: '$min_age', count: 0},
            ],
            labels: [],
            datasets: [
                {
                    backgroundColor: ['#172586', '#1e233a', '#afdfef'],
                    data: [],
                    borderWidth: 0,
                }
            ],
            labelsEmpty: [],
        }
    },
    computed: {
        currentMonth(vm = this) {
            return vm.$moment().format('MMMM')
        },
        doctorId(vm = this) {
            return vm.currentUser?.id || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadStatistics()
    },
    methods: {
        async loadStatistics() {
            const vm = this
            vm.loading = true

            let response = await httpService.get(`doctor/${vm.doctorId}/statistics`)
            // console.log('loadStatistics', response)

            let labels = ['Candidatos', 'no.', 'Formularios']

            response.data.chart.forEach((data, i) => {
                if (data > 0) {
                    vm.datasets[0].data.push(data)
                    vm.labels.push(labels[i])
                } else {
                    vm.labelsEmpty.push(labels[i])
                }
            })

            vm.statistics.forEach((statistic, i) => {
                statistic.count = response.data.statistics[i] || 0
            })

            vm.loading = false
        }
    }
}
</script>

<style scoped>

.title {
    text-align: center;
    font-weight: bold;
    color: #1a1a1a;
    text-transform: capitalize;
    font-size: 26px !important;
}

.labels {
    font-size: 14px;
    font-weight: bold;
}

</style>